import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../landing/Header/Header';
import Footer from '../landing/Footer/Footer';
import Apply from '../Form/Apply';
import Loader from '../../utils/Loader';

const CareerDetail1 = () => {

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            {loader && <Loader />}

            <Header />
            <section className="career-detail">
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="back-link">
                                <Link to="/career">Back to All Jobs</Link>
                            </div>
                            <div className="upper-parent">
                                <h4>Marketing Operations Manager position at Mezada Technology</h4>
                                <p className='para'>We’re looking for Marketing Operations Manager to join our team</p>
                                <div className="bottom-twice">
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>On-Site</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Full time</p>
                                </div>
                            </div>
                            <div className="bottom-detail">
                                <div className="main-content">
                                    <h6>About us:</h6>
                                    <p>At Mezada Technology, we firmly believe that the best way to predict the future is to invent it. We excel in providing cutting-edge tech solutions that meet customer needs and anticipate and adapt to evolving market trends. With a focus on innovation and customer satisfaction, our team delivers user-friendly platforms with customizable compensation structures. Whether it's UI/UX design, web development, mobile development, or blockchain solutions, we offer expertise, personalized solutions, and a commitment to quality assurance.</p>
                                    <h6>Job Description:</h6>
                                    <p>We're hiring a dedicated and result-oriented <span>Marketing Operations Manager</span> to assist the marketing team in tightening loose ends and taking charge of day-to-day tasks to make our routine more efficient and smooth. In this role, you will develop and execute a 360° support system for the team, handling communication with providers, producers, and logistics personnel.</p>
                                    <h6>Job requirements:</h6>
                                    <ul>
                                        <li>Previous experience in marketing operations, production or project management.</li>
                                        <li>Ability to effectively manage multiple tasks, deadlines, and priorities, with capabilities of seeing tasks from A to Z.</li>
                                        <li>Strong attention to detail to ensure accuracy in administrative tasks.</li>
                                        <li>Excellent written and verbal communication skills for interacting with vendors, event planners, team members, and customer support representatives.</li>
                                        <li>Familiarity with marketing tools, platforms, and software.</li>
                                        <li>Basic understanding of budget management and financial processes for handling invoices and subscription payments.</li>
                                        <li>Ability to proactively identify issues and find effective solutions, including reading manuals and reaching out to customer support when necessary.</li>
                                        <li>Strong teamwork and collaboration skills to work effectively with cross-functional teams and external partners.</li>
                                        <li>Ability to adapt to changing priorities and environments in a fast-paced marketing environment.</li>
                                    </ul>

                                    <h6>Advantages:</h6>
                                    <ul>
                                        <li>A bachelor's degree in marketing, business administration, communications, or a related field may be preferred.</li>
                                        <li>Knowledge of the crypto and blockchain realms.</li>
                                        <li>Experience in fieldwork with providers, producers, and logistic personnel.</li>
                                        <li>Basic understanding of information technology, cyber security, and regulation.</li>
                                        <li>Passion for the innovation and hi-tech industry.</li>
                                    </ul>
                                    <p>Please send your CV to: <a href="mailto: marketing@mezada.net">marketing@mezada.net</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <Apply setLoader={setLoader} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CareerDetail1
