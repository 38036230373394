import '../../App.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Header from './Header/Header.js';
import Specialities from './Specialities/Specialities.js';
import Choose from './Choose/Choose.js';
import Contactus from './Contactus/Contactus.js';
import Footer from './Footer/Footer.js';

function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <Banner />
      <Specialities />
      <Choose />
      <Contactus />
      <Footer />
    </>
  );
}

export default Landing;