import React, { useEffect } from 'react'
import './cookies.scss'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const Cookies = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Header />
            <section className="cookeis">
                <div className="custom-container">
                    <div className="maincontent">
                        <h2 className="heads">Cookie Policy</h2>
                        <p className="para">We recognize the importance of your privacy. In this cookie policy (“Cookie Policy”), we aim to inform you about the use of cookies on our Website (the “Services” as further detailed in the privacy policy). </p>
                        <p className="para mt-0">We use cookies on our Services for a variety of reasons which you can learn about below. We want our Services to be informative, personal, and as user friendly as possible and cookies help us to achieve that goal.</p>
                        <h2 className="headsinner">What are Cookies?</h2>
                        <p className="para">A cookie is a small piece of text sent to your browser by our Services. It helps the Services to remember information about your visit, your actions and preferences such as login, language, font size and other display preferences over a period of time, so you don’t have to keep re-entering them whenever you come back to the Services or browse from one page to another. Placing cookies can make your next visit easier and the Services more useful to you. </p>
                        <p className="para mt-0">We use several categories of cookies and each performs different functions. You can learn more about cookies and their functions generally by visiting an information website like  <a className='boldlink' target='_blank' href="https://www.allaboutcookies.org/">"https://www.allaboutcookies.org/"</a>. </p>
                   
                        <h2 className="headsinner">The Cookies We Use:</h2>
                        <p className="para"><span className='boldehead'>Strictly Necessary/Technical Cookies:</span> Strictly necessary cookies are essential in order to enable you to navigate the Services and use certain features. Because these cookies are strictly necessary we do not need to ask for your consent to use them. Without these strictly necessary cookies, the Services cannot be operated and provided to you. These cookies are necessary to allow us to operate our Services so you may access it as you have requested. These Cookies, for example, let us recognize that you have created an account and have logged into that account to access website or application content. They also include cookies that enable us to remember your previous actions within the same browsing session and secure our Services. These cookies also include cookies which are placed to make your interactions with our Services more secure. We use security cookies to authenticate users, prevent fraudulent use of login credentials, and protect user data from unauthorized parties.</p>
                        <p className="para mt-0"><span className='boldehead'>Analytical/Performance Cookies:</span> These cookies are used by us or third party service providers to analyze how the Services are used and their performance. For example, these cookies track which pages are most frequently visited, and from which locations our visitors come from. </p>
                        <p className="para mt-0"><span className='boldehead'>Functionality Cookies:</span> We may use cookies that allow us to offer you enhanced functionality when accessing or using our Services. Our functionality cookies are used to remember choices you make such as language preference, country location, or other online settings as well as identify you when you sign in to our Application or keep track of your specified preferences and interests. Functionality cookies enable us to improve and enhance the presentation of content on our Services.</p>
                        <p className="para mt-0"><span className='boldehead'>Third Party Advertising Cookies:</span> These cookies collect information about your activities on the Services and other websites to provide you targeted advertising. We may also allow our third party service providers to use cookies on the Services for the same purposes identified above, including collecting information about your online activities over time and across different websites. </p>
                        <h2 className="headsinner">Disabling Cookies</h2>
                        <p className="para">There are a number of ways for you to manage cookies and other tracking technologies. Through our cookie pop-up bar or your browser settings, you can accept or decline cookies that we place. You should be aware that you may not be able to use all our interactive features if you choose to decline the use of cookies. Most web browsers allow some control of most cookies through the browser settings. To find out how to manage cookies on popular browsers:
                        <br /><br />
                        <a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en" className='boldlink' target='_blank'>Google Chrome</a>
                        <br /><br />
                        <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" className='boldlink' target='_blank'>Mozilla Firefox</a>
                        <br /><br />
                        <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" className='boldlink' target='_blank'>Microsoft Internet Explorer</a>
                        <br /><br />
                        <a href="https://www.opera.com/help/tutorials/security/privacy/" className='boldlink' target='_blank'>Opera</a>
                        <br /><br />
                        <a href="https://support.apple.com/en-gb/safari" className='boldlink' target='_blank'>Apple Safari</a>
                        </p>
                        <h2 className="headsinner">Changes and Updates</h2>
                        <p className="para">We may update this Cookie Policy from time to time. We encourage you to periodically review this Cookie Policy to stay informed about our use of cookies, the information we collect via cookies, and any updates in relation thereto.</p>
                        <p className="para mt-0">We will post any changes to this Cookie Policy on our Services.</p>
                        <h2 className="headsinner">Contact Us</h2>
                        <p className="para">If you have questions about this Cookie Policy, please contact us.</p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Cookies
