import React from "react";
import "./footer.scss";
import { HashLink } from "react-router-hash-link";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;
  return (
    <>
      <section className="mainfooter">
        <div className="custom-container">
          <div className="innerfooter">
            <div className="footerleft">
              <img src="\logo.svg" alt="logo" className="logo" />
              {/* <p className="logopara">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium
                            </p> */}
            </div>
            <div className="footerright">
              <div className="footerlinks">
                <h6 className="footerlinkhead">Nav</h6>
                <div className="innerfooterlinks">
                  <HashLink
                    class="footerlinkpara"
                    to="/#home"
                    style={
                      isActive("#home")
                        ? {
                            color: "#4DD388",
                          }
                        : {}
                    }
                  >
                    Home
                  </HashLink>
                  <HashLink
                    class="footerlinkpara"
                    to="/#specialties"
                    style={
                      isActive("#specialties")
                        ? {
                            color: "#4DD388",
                          }
                        : {}
                    }
                  >
                    Our Specialties
                  </HashLink>
                  <HashLink
                    class="footerlinkpara"
                    to="/#choose"
                    style={
                      isActive("#choose")
                        ? {
                            color: "#4DD388",
                          }
                        : {}
                    }
                  >
                    Why Choose Us
                  </HashLink>
                  <HashLink
                    class="footerlinkpara"
                    to="/#contact"
                    style={
                      isActive("#contact")
                        ? {
                            color: "#4DD388",
                          }
                        : {}
                    }
                  >
                    Contact Us
                  </HashLink>
                </div>
              </div>
              <div className="footerlinks">
                <h6 className="footerlinkhead">Join us</h6>
                <div className="innerfooterlinks m-0">
                  {/* <a href="#" target='_blank' className="footerlinkpara">
                                        <img src="\assets\twitter.svg" alt="socialimg" className="socialimg" />
                                        Twitter</a>
                                    <a href="#" target='_blank' className="footerlinkpara">
                                        <img src="\assets\Telegram.svg" alt="socialimg" className="socialimg" />
                                        Telegram</a> */}
                  <Link
                    to={{
                      pathname: "https://www.linkedin.com/company/mezadadev",
                    }}
                    target="_blank"
                    className="footerlinkpara"
                  >
                    <img
                      src="\assets\LinkedIn.svg"
                      alt="socialimg"
                      className="socialimg"
                    />
                    LinkedIn
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="custom-container">
        <div className="lastline">
          <div className="lastlineleft">
          <p className="lastlineparaleft">
            Copyright © 2024 <span className="bold">Mezada Technologies LTD.</span> All
            Rights Reserved.
          </p>
          <div className="tppara">
            <Link to="/privacy" className="parainner">Privacy Policy</Link>
            <p className="parainner">|</p>
            <Link to="/terms" className="parainner">Terms & Conditions</Link>
          </div>
          </div>

          {/* <a href="#" className="toppara">
            Go to Top
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M10.5408 6.52009C10.43 6.52009 10.3192 6.47925 10.2317 6.39175L7.00001 3.16009L3.76834 6.39175C3.59917 6.56092 3.31917 6.56092 3.15001 6.39175C2.98084 6.22259 2.98084 5.94259 3.15001 5.77342L6.69084 2.23259C6.86001 2.06342 7.14001 2.06342 7.30917 2.23259L10.85 5.77342C11.0192 5.94259 11.0192 6.22259 10.85 6.39175C10.7683 6.47925 10.6517 6.52009 10.5408 6.52009Z"
                fill="#31F2B3"
              />
              <path
                d="M7 12.8959C6.76083 12.8959 6.5625 12.6975 6.5625 12.4584V2.64087C6.5625 2.4017 6.76083 2.20337 7 2.20337C7.23917 2.20337 7.4375 2.4017 7.4375 2.64087V12.4584C7.4375 12.6975 7.23917 12.8959 7 12.8959Z"
                fill="#31F2B3"
              />
            </svg>
          </a> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
