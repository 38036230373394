import React, { useState } from "react";
import "./contactus.scss";
import { Modal } from "react-bootstrap";
import axios from "axios"; // Import Axios

const Contactus = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageName, setErrorMessageName] = useState("");
  const [errorMessageNamePhone, setErrorMessagePhone] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async () => {
    if (!validateName(name)) {
      setErrorMessageName("Please enter a valid name.");
      return;
    }
    if (!validateEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }
    if (!validatePhoneNumber(phoneNumber)) {
      setErrorMessagePhone("Please enter a valid phone number.");
      return;
    }

    try {
      const response = await axios.post("https://api.mezada.net/contact", {
        email: email,
        phoneNumber: phoneNumber,
        name: name,
      });
      setName("");
      setEmail(" ");
      setPhoneNumber(" ");
      setErrorMessage(" ");
      setErrorMessageName(" ");
      setErrorMessagePhone(" ");
      handleShow();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const validateName = (name) => {
    return name.trim() !== "";
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    return phoneNumber.trim() !== "" && !isNaN(phoneNumber);
  };

  return (
    <>
      <section id="contact" className="contactmain">
        <div className="custom-container">
          <div className="innercontact">
            <div className="contactleft">
              <h4 className="contactmainhead">contact us</h4>
              <span className="contactline"></span>
              <p className="contactpara">
                For any inquiries or assistance, please provide your name, email
                address, and phone number in the fields below. We are committed
                to addressing your needs promptly and will ensure a swift
                response to your message
              </p>
            </div>
            <div className="contactright">
              <h6 className="inputlabel">Name</h6>
              <input
                type="text"
                className="contactinput"
                placeholder="Enter your name here..."
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrorMessageName(" ");
                }}
              />
              {errorMessageName && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    padding: 8,
                  }}
                >
                  {errorMessageName}
                </p>
              )}
              <h6 className="inputlabel">Email</h6>
              <input
                type="email"
                className="contactinput"
                placeholder="Enter your Email here..."
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrorMessage(" ");
                }}
              />
              {errorMessage && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    padding: 8,
                  }}
                >
                  {errorMessage}
                </p>
              )}
              <h6 className="inputlabel">Phone Number</h6>
              <input
                type="tel"
                className="contactinput"
                placeholder="Enter your Phone Number here..."
                value={phoneNumber}
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                  setErrorMessagePhone("");
                }}
              />
              {errorMessageNamePhone && (
                <p
                  className="error-message"
                  style={{
                    color: "red",
                    padding: 8,
                  }}
                >
                  {errorMessageNamePhone}
                </p>
              )}
              <button onClick={handleSubmit} className="submitbtn">
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="thankyoumodal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank you!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="innerthankyou">
            <div className="thankyouimg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="89"
                height="90"
                viewBox="0 0 89 90"
                fill="none"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="88"
                  height="89"
                  rx="44"
                  fill="white"
                  fill-opacity="0.08"
                />
                <path
                  d="M51.0744 16.7057H37.9255C35.0159 16.7057 32.6379 19.0824 32.6379 22.025V24.6847C32.6379 27.6273 34.988 30.004 37.8975 30.004H51.0744C53.9839 30.004 56.334 27.6273 56.334 24.6847V22.025C56.3619 19.0824 53.9839 16.7057 51.0744 16.7057Z"
                  fill="#31F2B3"
                />
                <path
                  d="M59.1597 24.6847C59.1597 29.1835 55.5227 32.8617 51.0745 32.8617H37.9256C33.4773 32.8617 29.8404 29.1835 29.8404 24.6847C29.8404 23.1002 28.1618 22.1099 26.763 22.8455C22.8183 24.9676 20.1326 29.1835 20.1326 34.0218V60.6468C20.1326 67.6072 25.7558 73.2943 32.638 73.2943H56.362C63.2442 73.2943 68.8675 67.6072 68.8675 60.6468V34.0218C68.8675 29.1835 66.1817 24.9676 62.2371 22.8455C60.8382 22.1099 59.1597 23.1002 59.1597 24.6847ZM53.8441 47.0655L42.6536 58.3832C42.2339 58.8076 41.7024 59.0057 41.1708 59.0057C40.6393 59.0057 40.1077 58.8076 39.6881 58.3832L35.4916 54.1391C34.6803 53.3185 34.6803 51.9604 35.4916 51.1399C36.3029 50.3193 37.6458 50.3193 38.4571 51.1399L41.1708 53.8844L50.8786 44.0663C51.69 43.2458 53.0328 43.2458 53.8441 44.0663C54.6555 44.8868 54.6555 46.2449 53.8441 47.0655Z"
                  fill="#31F2B3"
                />
              </svg>
            </div>
            <h6 className="thankyouhead">
              Your query has been submitted successfully!
            </h6>
            <p className="thankyoupara">
              Someone from our team will reach out to you shortly
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Contactus;
