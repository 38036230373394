import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Environment from '../../utils/Environment';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../utils/Loader';

const Apply = ({ setLoader }) => {

    const [show, setShow] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [resume, setResume] = useState(null);
    const [fileName, setFileName] = useState(null);

    const [errors, setErrors] = useState({
        name: '',
        email: '',
        resume: ''
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const uploadFile = (evt) => {
        const file = evt?.target?.files[0];
        console.log(file, "fileeeee"); // Log the entire file object
        if (file) {
            console.log(file.name, "selected file name"); // Log the file name
            setFileName(file?.name);
            setResume(file);
            setErrors(prevErrors => ({ ...prevErrors, resume: '' }));
        } else {
            console.log("No file selected");
        }
    };

    const validateForm = () => {
        const newErrors = { name: '', email: '', resume: '' };
        let isValid = true;

        if (!name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }

        // Regular expression for basic email validation
        const emailRe = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim() || !emailRe.test(email)) {
            newErrors.email = 'Valid email is required';
            isValid = false;
        }

        if (!resume) {
            newErrors.resume = 'Resume is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleInputChange = (setter, fieldName) => (event) => {
        setter(event.target.value);
        // Clear specific error when the user types
        setErrors(prevErrors => ({ ...prevErrors, [fieldName]: '' }));
    };

    const applyJobHandle = () => {
        if (!validateForm()) return;

        let data = new FormData();
        data.append("name", name);
        data.append("email", email);
        data.append("cv", resume);
        if (data) {
            setLoader(true);
            axios.post(Environment.backendUrl + "jobs", data)
                .then((response) => {
                    console.log(response, "response");
                    if (response) {
                        setLoader(false);
                        toast.success(response.data.message, {
                            position: "top-center",
                            autoClose: 2000,
                        });
                    }
                    handleShow();
                })
                .catch((err) => {
                    setLoader(false);
                    console.log(err?.response, "err 00000000000000oooooooooooooooooo", err?.response?.data?.details[0]?.email);
                    // console.error('Error:', err);
                    toast.error(err?.response?.data?.details[0]?.email || 'An error occurred', {
                        position: 'top-center'
                    });
                });
        }
    };

    return (
        <>
            <div className="apply-form">
                <div className="main-head">
                    <h6>Apply for this job</h6>
                    <p>Please fill out the details below</p>
                </div>
                <div className="option-field">
                    <input
                        type="text"
                        placeholder='Full Name'
                        value={name}
                        onChange={handleInputChange(setName, 'name')}
                    />
                    {errors.name && <p className="error">{errors.name}</p>}
                </div>
                <div className="option-field">
                    <input
                        type="text"
                        placeholder='Email'
                        value={email}
                        onChange={handleInputChange(setEmail, 'email')}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="option-field">
                    <div className="upload-div">
                        {
                            fileName ?
                                <p className='set-custom-style'>{fileName}</p>
                                :
                                <p>Resume/CV <span>(PDF, Word, TXT, 6MB Maximum)</span></p>
                        }
                        <label htmlFor="upload">Upload</label>
                        <input
                            type="file"
                            className='d-none'
                            id='upload'
                            onChange={uploadFile}
                        />
                    </div>
                    {errors.resume && <p className="error">{errors.resume}</p>}
                </div>
                <button onClick={applyJobHandle} className='btn-apply'>Apply</button>
            </div>

            <Modal className='submitted-modal' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <img src="\assets\check.svg" alt="img" className='img-fluid' />
                    <h6>Application Submitted successfully</h6>
                    <p>Thank you for submitting your application! Your information has been received successfully.</p>
                    <p>You will receive a confirmation email shortly with details of your submission. If there are any further steps required our team will be in touch.</p>
                    <Link to="/career">Okay</Link>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Apply;
