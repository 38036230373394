import React, { useEffect } from 'react'
import "./career.scss"
import Header from '../landing/Header/Header'
import Footer from '../landing/Footer/Footer'
import { Link } from 'react-router-dom'

const Career = () => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);

    return (
        <>
            <Header />
            <section className="career-section">
                <img src="\assets\career-bg.png" alt="img" className='img-fluid career-bg' />
                <div className="custom-container">
                    <div className="main-heading">
                        <h6>Be the part of our Team</h6>
                        <p>We’re looking for passionate people to join our team. We vale flat hierarchies, clear communication, and ownership and responsibility </p>
                    </div>
                    <div className="bottom-content">
                        <div className="upper-heading">
                            <h6>Open Job Positions</h6>
                        </div>
                        <div className="bottom-cards">
                            <Link to="/headofmarketing">
                                <div className="single-card">
                                    <div className="left-content">
                                        <h6>Head of Marketing</h6>
                                        <p>We’re looking for Head of Marketing to join our team</p>
                                        <div className="bottom-twice">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>100% Remote</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>Full time</p>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <a href="#">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#4DD388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a>
                                    </div>
                                </div>
                              
                            </Link>
                            <Link to="/marketingoperationsmanager">
                                <div className="single-card">
                                    <div className="left-content">
                                        <h6>Marketing Operations Manager</h6>
                                        <p>We’re looking for Marketing Operations Manager to join our team</p>
                                        <div className="bottom-twice">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>On-Site</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>Full time</p>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <a href="#">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#4DD388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a>
                                    </div>
                                </div>
                              
                            </Link>
                            <Link to="/headofprandcommunications">
                                <div className="single-card">
                                    <div className="left-content">
                                        <h6>Head of PR and Communications</h6>
                                        <p>We’re looking for Head of PR and Communications to join our team</p>
                                        <div className="bottom-twice">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>100% Remote</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>Full time</p>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <a href="#">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#4DD388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a>
                                    </div>
                                </div>
                              
                            </Link>
                            <Link to="/headofcommunity">
                                <div className="single-card">
                                    <div className="left-content">
                                        <h6>Head of Community</h6>
                                        <p>We’re looking for Head of Community to join our team</p>
                                        <div className="bottom-twice">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>On-Site</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>Full time</p>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <a href="#">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#4DD388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a>
                                    </div>
                                </div>
                              
                            </Link>
                            <Link to="/supportspecialist">
                                <div className="single-card">
                                    <div className="left-content">
                                        <h6>Support Specialist</h6>
                                        <p>We’re looking for Support Specialist to join our team</p>
                                        <div className="bottom-twice">
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>On-Site</p>
                                            <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>Full time</p>
                                        </div>
                                    </div>
                                    <div className="right-content">
                                        <a href="#">Apply Now <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M7 17L17 7M17 7H9M17 7V15" stroke="#4DD388" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></a>
                                    </div>
                                </div>
                              
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Career
