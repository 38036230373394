import React from 'react'
import './specialities.scss'

const Specialities = () => {
    return (
        <>
            <section id='specialties' className="mainspeciality">
                <div className="custom-container">
                    <h5 className="specialhead">
                        our specialties
                    </h5>
                    <p className="specialpara">
                        Experience a user-friendly platform with customizable compensation structures.
                    </p>
                    <div className="innerspaeciality">
                        <div className="specialcard">
                            <div className="specialcardimg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="innerspecialcardimg">
                                    <path d="M50.7298 48.1869C53.9012 48.1869 56.4814 45.6154 56.4814 42.4443V8.39C56.4814 5.41553 54.0642 2.99854 51.09 2.99854H8.91002C5.93555 2.99854 3.51855 5.41553 3.51855 8.39V42.4443C3.51855 45.6154 6.09835 48.1869 9.26995 48.1869H22.424V51.484H20.8795C19.4686 51.484 18.3193 52.6233 18.3193 54.0341V55.9564C18.3193 56.5315 18.7896 57.0015 19.3644 57.0015H40.6394C41.2142 57.0015 41.6844 56.5315 41.6844 55.9564V54.0341C41.6844 52.6233 40.5352 51.484 39.1243 51.484H37.5758V48.1869H50.7298ZM30.0042 45.4012C29.1899 45.4012 28.5299 44.7499 28.5299 43.9355C28.5299 43.1127 29.1899 42.4526 30.0042 42.4526C30.8098 42.4526 31.4699 43.1127 31.4699 43.9355C31.4699 44.7499 30.8098 45.4012 30.0042 45.4012ZM5.23284 39.6929V8.39C5.23284 6.36726 6.88707 4.71282 8.91002 4.71282H51.09C53.1127 4.71282 54.7672 6.36726 54.7672 8.39V39.6929H5.23284Z" fill="#114B38" />
                                    <path d="M45.3727 11.4842C44.3013 11.4842 43.4012 12.153 43.0499 13.1043H32.5413C32.1899 12.153 31.2813 11.4842 30.2185 11.4842C29.1556 11.4842 28.2556 12.153 27.9042 13.1043H16.9499C16.5984 12.153 15.6985 11.4842 14.6271 11.4842C13.2642 11.4842 12.1584 12.5899 12.1584 13.9614C12.1584 15.3241 13.2642 16.4299 14.6271 16.4299C15.6985 16.4299 16.5984 15.7615 16.9499 14.8186H23.087C18.6041 17.1929 15.4842 21.8126 15.2271 27.1785H14.6786C14.1899 27.1785 13.7871 27.5728 13.7871 28.07V30.8469C13.7871 31.3357 14.1899 31.7383 14.6786 31.7383H17.4643C17.9527 31.7383 18.3558 31.3357 18.3558 30.8469V28.07C18.3558 27.5728 17.9527 27.1785 17.4643 27.1785H16.9413C17.2755 20.9814 21.9555 15.9155 27.9643 14.9814C28.2298 15.5472 28.6842 15.9925 29.2584 16.2411C29.25 16.2583 29.2412 16.2671 29.2328 16.2842L22.9156 28.9183C22.77 29.2184 22.8043 29.5871 23.0269 29.8441C23.2412 30.1099 23.5928 30.2212 23.9184 30.127L30.0042 28.3956L36.0814 30.127C36.4831 30.2165 36.7797 30.0881 36.9727 29.8441C37.1957 29.5871 37.2384 29.2184 37.0842 28.9183L30.8013 16.3529C31.5214 16.1901 32.1213 15.7012 32.4386 15.05C38.2584 16.1558 42.7243 21.1187 43.0499 27.1785H42.5357C42.0471 27.1785 41.6443 27.5728 41.6443 28.07V30.8469C41.6443 31.3357 42.0471 31.7383 42.5357 31.7383H45.3214C45.8099 31.7383 46.2129 31.3357 46.2129 30.8469V28.07C46.2129 27.5728 45.8099 27.1785 45.3214 27.1785H44.7642C44.507 21.8126 41.3871 17.1929 36.9042 14.8186H43.0499C43.4012 15.7615 44.3013 16.4299 45.3727 16.4299C46.7356 16.4299 47.8414 15.3241 47.8414 13.9614C47.8414 12.5899 46.7356 11.4842 45.3727 11.4842Z" fill="#114B38" />
                                </svg>
                            </div>
                            <h6 className="specialcardhead">UI/UX</h6>
                            <p className="specialcardpara">Our UI/UX designers are experts at creating intuitive interfaces that enhance user satisfaction and increase engagement.</p>
                            <a href="/" target='_blank' className="viewmorelink">View
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M9.74999 8.81249C9.60749 8.81249 9.46499 8.75999 9.35249 8.64749C9.13499 8.42999 9.13499 8.06999 9.35249 7.85249L15.5025 1.70249C15.72 1.48499 16.08 1.48499 16.2975 1.70249C16.515 1.91999 16.515 2.27999 16.2975 2.49749L10.1475 8.64749C10.035 8.75999 9.89249 8.81249 9.74999 8.81249Z" fill="#31F2B3" />
                                    <path d="M16.5 5.6625C16.1925 5.6625 15.9375 5.4075 15.9375 5.1V2.0625H12.9C12.5925 2.0625 12.3375 1.8075 12.3375 1.5C12.3375 1.1925 12.5925 0.9375 12.9 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5V5.1C17.0625 5.4075 16.8075 5.6625 16.5 5.6625Z" fill="#31F2B3" />
                                    <path d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z" fill="#31F2B3" />
                                </svg>
                            </a>
                        </div>
                        <div className="specialcard">
                            <div className="specialcardimg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none" className="innerspecialcardimg">
                                    <g clip-path="url(#clip0_26_355)">
                                        <path d="M54 12.7617V4.85156C54 2.23457 51.8709 0.105469 49.2539 0.105469H4.74609C2.1291 0.105469 0 2.23457 0 4.85156V12.7617H54ZM36.4922 6.43359H45.9844C46.8581 6.43359 47.5664 7.14192 47.5664 8.01562C47.5664 8.88933 46.8581 9.59766 45.9844 9.59766H36.4922C35.6185 9.59766 34.9102 8.88933 34.9102 8.01562C34.9102 7.14192 35.6185 6.43359 36.4922 6.43359ZM20.6719 6.43359C21.5456 6.43359 22.2539 7.14192 22.2539 8.01562C22.2539 8.88933 21.5456 9.59766 20.6719 9.59766C19.7982 9.59766 19.0898 8.88933 19.0898 8.01562C19.0898 7.14192 19.7982 6.43359 20.6719 6.43359ZM14.3438 6.43359C15.2175 6.43359 15.9258 7.14192 15.9258 8.01562C15.9258 8.88933 15.2175 9.59766 14.3438 9.59766C13.47 9.59766 12.7617 8.88933 12.7617 8.01562C12.7617 7.14192 13.47 6.43359 14.3438 6.43359ZM8.01562 6.43359C8.88933 6.43359 9.59766 7.14192 9.59766 8.01562C9.59766 8.88933 8.88933 9.59766 8.01562 9.59766C7.14192 9.59766 6.43359 8.88933 6.43359 8.01562C6.43359 7.14192 7.14192 6.43359 8.01562 6.43359Z" fill="#31F2B3" />
                                        <path d="M0 15.9258V49.1484C0 51.7654 2.1291 53.8945 4.74609 53.8945H49.2539C51.8709 53.8945 54 51.7654 54 49.1484V15.9258H0ZM18.4961 38.4209C19.1784 38.9667 19.289 39.9622 18.7432 40.6445C18.1975 41.3267 17.2017 41.4374 16.5196 40.8915L8.60941 34.5634C7.8185 33.9309 7.81798 32.7257 8.60941 32.0927L16.5196 25.7645C17.2015 25.2187 18.1973 25.3293 18.7432 26.0115C19.289 26.6938 19.1784 27.6894 18.4961 28.2351L12.1302 33.3281L18.4961 38.4209ZM33.2002 22.8771L23.708 45.0256C23.3639 45.8285 22.434 46.2008 21.6307 45.8564C20.8277 45.5123 20.4556 44.5822 20.7998 43.7791L30.292 21.6307C30.6362 20.8277 31.5663 20.4557 32.3693 20.7998C33.1723 21.144 33.5444 22.074 33.2002 22.8771ZM45.3906 34.5635L37.4804 40.8916C36.7993 41.4367 35.8035 41.3278 35.2568 40.6446C34.711 39.9623 34.8216 38.9667 35.5039 38.421L41.8698 33.3281L35.5039 28.2354C34.8216 27.6896 34.711 26.694 35.2568 26.0118C35.8025 25.3295 36.7982 25.2188 37.4804 25.7647L45.3906 32.0929C46.1815 32.7254 46.182 33.9305 45.3906 34.5635Z" fill="#31F2B3" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_26_355">
                                            <rect width="54" height="54" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <h6 className="specialcardhead">WEB DEVELOPMENT</h6>
                            <p className="specialcardpara">From your raw ideas of robust e-commerce platforms,</p>
                            <a href="/" target='_blank' className="viewmorelink">View
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M9.74999 8.81249C9.60749 8.81249 9.46499 8.75999 9.35249 8.64749C9.13499 8.42999 9.13499 8.06999 9.35249 7.85249L15.5025 1.70249C15.72 1.48499 16.08 1.48499 16.2975 1.70249C16.515 1.91999 16.515 2.27999 16.2975 2.49749L10.1475 8.64749C10.035 8.75999 9.89249 8.81249 9.74999 8.81249Z" fill="#31F2B3" />
                                    <path d="M16.5 5.6625C16.1925 5.6625 15.9375 5.4075 15.9375 5.1V2.0625H12.9C12.5925 2.0625 12.3375 1.8075 12.3375 1.5C12.3375 1.1925 12.5925 0.9375 12.9 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5V5.1C17.0625 5.4075 16.8075 5.6625 16.5 5.6625Z" fill="#31F2B3" />
                                    <path d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z" fill="#31F2B3" />
                                </svg>
                            </a>
                        </div>
                        <div className="specialcard">
                            <div className="specialcardimg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="innerspecialcardimg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M40.9475 21.225L35.495 37.59C35.1675 38.57 35.6975 39.6325 36.68 39.96C37.6625 40.2875 38.725 39.7575 39.0525 38.775L44.505 22.41C44.8325 21.43 44.3025 20.3675 43.32 20.04C42.3375 19.7125 41.275 20.2425 40.9475 21.225Z" fill="#31F2B3" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M45.72 25.8725L49.8475 30L45.72 34.1275C44.9875 34.86 44.9875 36.0475 45.72 36.78C46.4525 37.5125 47.64 37.5125 48.3725 36.78L53.825 31.325C54.5575 30.5925 54.5575 29.4075 53.825 28.675L48.3725 23.22C47.64 22.4875 46.4525 22.4875 45.72 23.22C44.9875 23.9525 44.9875 25.14 45.72 25.8725Z" fill="#31F2B3" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.6275 23.22L26.175 28.675C25.4425 29.4075 25.4425 30.5925 26.175 31.325L31.6275 36.78C32.36 37.5125 33.5475 37.5125 34.28 36.78C35.0125 36.0475 35.0125 34.86 34.28 34.1275L30.1525 30L34.28 25.8725C35.0125 25.14 35.0125 23.9525 34.28 23.22C33.5475 22.4875 32.36 22.4875 31.6275 23.22Z" fill="#31F2B3" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M41.875 38.21L41.4225 39.565C40.66 41.855 38.18 43.095 35.89 42.3325C34.625 41.91 33.68 40.9675 33.2175 39.82C32.0125 39.8925 30.7825 39.4675 29.86 38.5475L24.4075 33.0925C22.6975 31.385 22.6975 28.615 24.4075 26.9075L29.86 21.4525C31.5675 19.745 34.34 19.745 36.0475 21.4525C36.8175 22.2225 37.24 23.2075 37.3175 24.215L38.5775 20.435C39.0925 18.89 40.385 17.825 41.875 17.5275V10C41.875 8.1775 41.15 6.4275 39.8625 5.1375C38.5725 3.85 36.8225 3.125 35 3.125C28.89 3.125 18.61 3.125 12.5 3.125C10.6775 3.125 8.9275 3.85 7.6375 5.1375C6.35 6.4275 5.625 8.1775 5.625 10V50C5.625 51.8225 6.35 53.5725 7.6375 54.8625C8.9275 56.15 10.6775 56.875 12.5 56.875H35C36.8225 56.875 38.5725 56.15 39.8625 54.8625C41.15 53.5725 41.875 51.8225 41.875 50V38.21ZM18.75 50.625H28.75C29.785 50.625 30.625 49.785 30.625 48.75C30.625 47.715 29.785 46.875 28.75 46.875H18.75C17.715 46.875 16.875 47.715 16.875 48.75C16.875 49.785 17.715 50.625 18.75 50.625ZM33.75 6.875H13.75L15.3075 10.7675C15.8775 12.1925 17.255 13.125 18.79 13.125H28.71C30.245 13.125 31.6225 12.1925 32.1925 10.7675L33.75 6.875Z" fill="#31F2B3" />
                                </svg>
                            </div>
                            <h6 className="specialcardhead">MOBILE DEVELOPMENT</h6>
                            <p className="specialcardpara">Our mobile development team excels in building native and cross-platform apps that captivate users and drive business growth.</p>
                            <a href="/" target='_blank' className="viewmorelink">View
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M9.74999 8.81249C9.60749 8.81249 9.46499 8.75999 9.35249 8.64749C9.13499 8.42999 9.13499 8.06999 9.35249 7.85249L15.5025 1.70249C15.72 1.48499 16.08 1.48499 16.2975 1.70249C16.515 1.91999 16.515 2.27999 16.2975 2.49749L10.1475 8.64749C10.035 8.75999 9.89249 8.81249 9.74999 8.81249Z" fill="#31F2B3" />
                                    <path d="M16.5 5.6625C16.1925 5.6625 15.9375 5.4075 15.9375 5.1V2.0625H12.9C12.5925 2.0625 12.3375 1.8075 12.3375 1.5C12.3375 1.1925 12.5925 0.9375 12.9 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5V5.1C17.0625 5.4075 16.8075 5.6625 16.5 5.6625Z" fill="#31F2B3" />
                                    <path d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z" fill="#31F2B3" />
                                </svg>
                            </a>
                        </div>
                        <div className="specialcard">
                            <div className="specialcardimg">
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="innerspecialcardimg">
                                    <g clip-path="url(#clip0_26_383)">
                                        <path d="M0 53.6398C0 54.3059 0.375824 54.9138 0.971832 55.2122L10.6641 60V47.0242L0 41.7508V53.6398Z" fill="#31F2B3" />
                                        <path d="M14.1797 47.0242V60L23.7552 55.2122C24.3507 54.9138 24.7266 54.3063 24.7266 53.6398V49.4531H35.2734V53.6398C35.2734 54.3063 35.6493 54.9138 36.2453 55.2122L45.8203 60V47.0242L35.2734 41.7508V45.9375H24.7266V41.7503L14.1797 47.0242Z" fill="#31F2B3" />
                                        <path d="M13.2083 32.0608C12.713 31.8132 12.1307 31.8132 11.6359 32.0608L0.207825 37.9234L12.4219 43.9719L24.5187 37.9234L19.3858 35.2624L24.3127 26.277L28.2422 28.3594V15.2664L17.6953 9.99298V21.882C17.6953 22.5481 18.0711 23.156 18.6671 23.4544L21.1656 24.7041L16.2639 33.6447L13.2083 32.0608Z" fill="#31F2B3" />
                                        <path d="M49.3359 60L59.0286 55.2122C59.6242 54.9138 60 54.3063 60 53.6398V41.7503L49.3359 47.0242V60Z" fill="#31F2B3" />
                                        <path d="M42.3047 21.882V9.99252L31.7578 15.2664V28.3594L35.6877 26.2775L40.6142 35.2629L35.4813 37.9234L47.5781 43.9719L59.7922 37.9234L48.3646 32.0604C47.8693 31.8127 47.287 31.8127 46.7921 32.0604L43.7361 33.6447L38.8344 24.7041L41.3333 23.4544C41.9289 23.156 42.3047 22.5481 42.3047 21.882Z" fill="#31F2B3" />
                                        <path d="M29.214 0.185856L17.9031 6.16562L30 12.2141L42.0969 6.16562L30.7864 0.185398C30.2911 -0.0617942 29.7089 -0.0617942 29.214 0.185856Z" fill="#31F2B3" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_26_383">
                                            <rect width="60" height="60" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <h6 className="specialcardhead">BLOCKCHAIN</h6>
                            <p className="specialcardpara">Smart contract development, decentralized applications, or blockchain integration, our team is here to guide you every step of the way.</p>
                            <a href="/" target='_blank' className="viewmorelink">View
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M9.74999 8.81249C9.60749 8.81249 9.46499 8.75999 9.35249 8.64749C9.13499 8.42999 9.13499 8.06999 9.35249 7.85249L15.5025 1.70249C15.72 1.48499 16.08 1.48499 16.2975 1.70249C16.515 1.91999 16.515 2.27999 16.2975 2.49749L10.1475 8.64749C10.035 8.75999 9.89249 8.81249 9.74999 8.81249Z" fill="#31F2B3" />
                                    <path d="M16.5 5.6625C16.1925 5.6625 15.9375 5.4075 15.9375 5.1V2.0625H12.9C12.5925 2.0625 12.3375 1.8075 12.3375 1.5C12.3375 1.1925 12.5925 0.9375 12.9 0.9375H16.5C16.8075 0.9375 17.0625 1.1925 17.0625 1.5V5.1C17.0625 5.4075 16.8075 5.6625 16.5 5.6625Z" fill="#31F2B3" />
                                    <path d="M11.25 17.0625H6.75C2.6775 17.0625 0.9375 15.3225 0.9375 11.25V6.75C0.9375 2.6775 2.6775 0.9375 6.75 0.9375H8.25C8.5575 0.9375 8.8125 1.1925 8.8125 1.5C8.8125 1.8075 8.5575 2.0625 8.25 2.0625H6.75C3.2925 2.0625 2.0625 3.2925 2.0625 6.75V11.25C2.0625 14.7075 3.2925 15.9375 6.75 15.9375H11.25C14.7075 15.9375 15.9375 14.7075 15.9375 11.25V9.75C15.9375 9.4425 16.1925 9.1875 16.5 9.1875C16.8075 9.1875 17.0625 9.4425 17.0625 9.75V11.25C17.0625 15.3225 15.3225 17.0625 11.25 17.0625Z" fill="#31F2B3" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Specialities