import React, { useEffect } from "react";
import "./privacypolicy.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const Privacypolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
    <Header />
      <section className="mainprivacy">
        <div className="custom-container">
          <div className="maincontent">
            <h2 className="heads">
              Mezada Development and Software Ltd Privacy Policy
            </h2>
            <p className="para">
              Mezada Development and Software Ltd, Israeli company number
              515306199 ("Mezada" or "We") is committed to maintaining the
              privacy of its users ("You" or "User"). Mezada adheres to strict
              data protection practices and implements data protection policies
              and procedures to assure that the privacy of its users will be
              adequately protected. The following information describes how
              Mezada collects and processes information about you when you use
              our Services and Website (as defined below).
            </p>
            <p className="para">Our Privacy Policy explains:</p>
            <ul>
              <li>What information we collect and why we collect it.</li>
              <li>How we use that information.</li>
              <li>How we secure your information.</li>
              <li>
                Your rights with regard to the collection of such information.
              </li>
            </ul>
            <h2 className="headsinner formargin">General</h2>
            <p className="para">
              Mezada provides software development services (the{" "}
              <span>"Services"</span>). Mezada’s website provides information on
              the Services and enables users to contact Mezada when they wish to
              do so <span>("Website")</span>.
            </p>

            <h2 className="headsinner">Using our Website via your Device</h2>
            <p className="para">
              Users can access our Website and Website on a device{" "}
              <span>("Device")</span>. A Device is any computer used to access
              the Website, including without limitation a desktop, laptop,
              mobile phone, tablet, or other consumer electronic device. This
              Privacy Policy governs your access to the Website, regardless of
              how you access it, and by using our and Website you consent to the
              collection, transfer, processing, storage, disclosure and other
              uses described in this Privacy Policy.
            </p>

            <h2 className="headsinner">Collecting Information</h2>
            <p className="para">
              Please note that the scope of this Privacy Policy is limited only
              to information collected by Mezada through your use of its
              Website. Some information may be automatically collected, and some
              is collected when you interact with our Website. The type of
              information that may be automatically collected is non-personal
              information, which includes your session durations, the content
              you accessed on the Website, information about your Device and
              your internet connection and the frequency and scope of your use
              of the Website.
            </p>
            <p className="para">
              Information from which you can be personally identified may also
              be collected, including but not limited to your name, telephone
              number, username, social networks accounts (including but not
              limited to twitter and discord), email address, IP, and the
              location of the Device through which you used the Website
              (hereinafter: "Personal Information") Your Personal Information
              will be collected, without limitation, as described below:
            </p>
            <ul>
              <li>
                Contacting Us If you wish to contact us for support regarding
                our Services this Privacy Policy or any other matter, we will
                collect your name, email address, phone number and the content
                you submitted to facilitate your inquiry.
              </li>
            </ul>
            <h2 className="headsinner formargin">Use of Information:</h2>
            <p className="para">
              We use the Personal Information we collect from you for a range of
              different business purposes according to different legal bases of
              processing. We may use or process your Personal Information for
              the following purposes. One or more purposes may apply
              simultaneously.
            </p>
            <h2 className="headsinner">Providing the Requested Services:</h2>
            <ul>
              <li>
                We collect your Personal Information to provide you with the
                Services you contracted to receive. Such collection of
                information will enable us to provide you with technical and
                professional assistance, with regard to the functionality of the
                Website and Services or to any inquiry you submit to us through
                it.
              </li>
            </ul>
            <p className="para">
              We process the Personal Information where it is necessary for the
              adequate performance of the contract regarding your use of our
              Website.
            </p>
            <h2 className="headsinner">
              Improvement and Development of the Services:
            </h2>
            <ul>
              <li>
                We collect Personal Information to improve and develop our
                Website and understand feedback on Mezada’s Services and to help
                provide more information on the use of those Services via our
                Website.
              </li>
              <li>
                We collect Personal Information for the ongoing review and
                improvement of the information provided on our Website to ensure
                they are user friendly. 
              </li>
              <li>
                We collect Personal Information to improve the management and
                administration of our business and maintain compliancy with our
                internal policies and procedures.
              </li>
              <li>
                We conduct surveys and research, test features in development,
                and analyze the information we have to evaluate and improve our
                Website and Services, develop new features, and conduct audits
                and troubleshooting activities. 
              </li>
            </ul>
            <p className="para">
              We process this information in light of our legitimate interest in
              improving the Website and Services, to allow our users to have the
              best experience.
            </p>
            <h2 className="headsinner">
              Maintain a Safe and Secure Environment
            </h2>
            <ul>
              <li>
                Verify and authenticate your identity and prevent unauthorized
                or illegal activity.
              </li>
              <li>
                Enhance the safety and security of our Services and Website.
              </li>
              <li>Conduct security investigations and risk assessments.</li>
              <li>
                Prevent or take action against activities that are, or may be,
                in breach of our terms of service or applicable law. 
              </li>
            </ul>
            <p className="para">
              We process this information in light of our legitimate interest in
              improving our Services and Website by enabling our users to browse
              in a secure environment.
            </p>
            <h2 className="headsinner">
              Personalize Content, Advertising and Marketing
            </h2>
            <ul>
              <li>
                If you have used Mezada’s Services in the past, we have a
                legitimate business interest for matching the data we collect
                with other data we had already collected. 
              </li>
              <li>
                This enables us to understand your needs and interests, optimize
                the content we send you, provide you with relevant notifications
                and make the Website and Services more suitable and relevant to
                your needs.
              </li>
              <li>
                This also enables us to improve your experience on the Website
                by providing you with personalized content, recommendations, and
                features.
              </li>
            </ul>
            <p className="para">
              We process this information in light of our legitimate interest to
              personalize your experience and customize our content.
            </p>
            <h2 className="headsinner">
              Disclosure of Information and Transfer of Data 
            </h2>
            <p className="para">
              Except as otherwise provided in this Privacy Policy, we reasonably
              attempt to ensure that we never intentionally disclose any of your
              Personal Information, to any third party without having received
              your permission, except as provided for herein or otherwise as
              permitted or required under law. We may share your Personal
              Information with our affiliates, subsidiaries, partners or any
              third party companies and individuals to facilitate our Services
              or any portion thereof, and our partners utilizing the Website, as
              well as marketing, storage, data management or maintenance
              services. We may also share your information with analytics
              service providers for analytics purposes. Such analytics service
              providers set their own cookies or other identifiers on your
              Device, through which they can collect information about your
              usage of our Website. This helps us compile aggregated statistics
              about the effectiveness of our Website and Services. 
            </p>
            <p className="para">
              The above mentioned third parties may be located in countries
              other than your own, and we may send them information we receive.
              When such third parties process your Personal Information on our
              behalf, we will assure that they comply with obligations similar
              to those which are set forth in this Privacy Policy. We will also
              assure that they will abide by our data privacy and security
              requirements, and will be allowed to use the Personal Information
              solely for the purposes we set. We will transfer your Personal
              Information while using appropriate and suitable safeguards, while
              using a variety of legal mechanisms, including contracts, to
              ensure your rights and protections travel with your data.
            </p>
            <p className="para">
              We may also transfer your information, including Personal
              Information, in connection with a corporate merger, consolidation,
              the sale of related assets or corporate division or other
              fundamental corporate changes. Furthermore, information about you
              may also be released in order to comply with any valid legal
              inquiry or process such as a search warrant, subpoena, statute or
              court order. We will also release specific information in special
              cases, such as if you use the Services or Website to perform an
              unlawful act or omission or take any act or omission that may
              damage Mezada, its property and goodwill, or if there is an
              attempted breach of the security of the Website and/or Services or
              a physical or property threat to you or others. With respect to
              our data protection practices, you have the right to file a
              complaint to any relevant supervisory data protection authority.
            </p>
            <h2 className="headsinner">Your Rights</h2>
            <p className="para">
              You have the right at any time to request to access or modify your
              information. To exercise these options, please contact us at
              privacy@mezada.net.
            </p>
            <p className="para">
              In some jurisdictions, in particular those located within the
              European Union (the <span>"EU"</span>) or within the European
              Economic Area (the <span>"EEA"</span>), you may be afforded
              specific rights regarding your Personal Information. Subject to
              such eligibility, you may have the following rights to:
            </p>
            <ul>
              <li>
                Request a rectification of your Personal Information where the
                information we hold about you is incorrect or incomplete.
              </li>
              <li>
                Object to the processing of your Personal Information for direct
                marketing purposes.
              </li>
              <li>
                Object to the processing of your Personal Information where our
                legal basis for that processing is that such processing is
                necessary for our legitimate interests.
              </li>
              <li>
                Object to an automated decision-making (including profiling) in
                certain circumstances.
              </li>
              <li>
                Request the erasure of your Personal Information in certain
                circumstances, such as where processing is no longer necessary
                for the purpose it was originally collected for, and there is no
                compelling reason for us to continue to process or store it;
              </li>
              <li>
                Receive your Personal Information, or ask us to transfer it to
                another organization that you have provided to us, which we
                process by automated means, where our processing is either based
                on your consent or is necessary for the performance of a
                contract with you.
              </li>
            </ul>
            <p className="para">
              If you wish to file a request regarding any of the above, you may
              contact us at:{" "}
              <a href="mailto: privacy@Mezada.net">privacy@Mezada.net</a>.
            </p>
            <h2 className="headsinner">Cookies</h2>
            <p className="para">
              We may use "cookies" and/or other technologies or files
              (collectively, <span>"cookies"</span>) to identify how users make
              use of our Website and Services. This aggregated tracking
              information may be used to help us improve and enhance the Website
              and Services’ experience for all of our users. In addition,
              cookies are used for adjusting the Website and Services to your
              personal preferences. Cookies contain information such as the
              pages you visited, the length of time you stayed on the Website,
              the location from which you accessed the Website and more. If you
              would prefer not to have cookies stored on your Device, you may
              modify your browser settings to reject most cookies, or manually
              remove cookies that have been placed on your computer. However, by
              rejecting the cookies, you may be unable to fully access the
              offerings on our Services and Website. To find out more about
              cookies, visit{" "}
              <a href="www.allaboutcookies.org" target="_blank">
                www.allaboutcookies.org
              </a>{" "}
              .
            </p>
            <h2 className="headsinner">Opt In or Opt Out</h2>
            <p className="para">
              If, at any time, you would like to stop using our Service or opt
              out of a feature, or stop receiving relevant materials from us,
              you may notify us by writing to{" "}
              <a href="mailto: privacy@Mezada.net">privacy@Mezada.net</a>. You
              should be aware, however, that it is not always possible to
              completely remove or modify information in our databases and
              servers, although we will always make reasonable efforts to do so
              upon your request. 
            </p>
            <h2 className="headsinner">Links to Other Websites</h2>
            <p className="para">
              The Website may provide links to other websites. Please be aware
              that these other websites are not covered by our Privacy Policy.
              This Privacy Policy does not cover the information practices
              exercised by other providers of products or services, advertisers
              or other websites, companies or individuals, which are not owned
              or controlled by Mezada. We suggest that when linking to another
              website, you always read that website’s privacy policy before
              volunteering any personally identifiable information.
            </p>
            <h2 className="headsinner">Data Security</h2>
            <p className="para">
              We deploy industry standard, or better measures to ensure the
              security, confidentiality, integrity and availability of Personal
              Information we process. We maintain physical, technical and
              administrative safeguards, and test and update these periodically.
              We endeavor to restrict access to Personal Information on a ‘need
              to know’ basis for the provision of Services to you. In the event
              of a security breach, we will take all reasonable action to
              minimize any harm, including with regard to notifications.
              Although we will do our best to protect Personal Information, we
              cannot guarantee the security of data transmitted to Website, and
              transmission is at the users own risk.
            </p>
            <h2 className="headsinner">Data Retention </h2>
            <p className="para">
              Generally, Mezada does not retain information longer than
              necessary to provide its Services and for its reasonable business
              and lawful needs. If you withdraw your consent to us processing
              your Personal Information, we will erase your Personal Information
              from our systems, unless the information is required for Mezada to
              establish, exercise or defend against legal claims or it is
              necessary for the performance of the requested Services.
            </p>
            <h2 className="headsinner">
              California Online Privacy Protection Act
            </h2>
            <p className="para">
              CalOPPA requires commercial websites and online services to post a
              privacy policy. The law's reach stretches well beyond California
              to require any person or company in the United States (and
              conceivably the world) that operates websites collecting
              Personally Identifiable Information from California consumers, to
              post a conspicuous privacy policy on its website stating exactly
              the information being collected and those individuals or companies
              with whom it is being shared. See more at:
              <a
                href="http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf"
                target="_blank"
              >
                http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
              </a>
              . 
            </p>
            <h2 className="headsinner">
              According to CalOPPA, we agree to the following:
            </h2>
            <ul>
              <li>
                Once this Privacy Policy is created, we will add a link to it on
                the first significant page after entering our Website.
              </li>
              <li>
                Our Privacy Policy link includes the word 'Privacy' and can
                easily be found on the Website. 
              </li>
              <li>
                You can request to change your Personal Information by emailing
                us.
              </li>
            </ul>
            <h2 className="headsinner formargin">California Privacy Rights</h2>
            <p className="para">
              The California Consumer Privacy Act of 2018 ("CCPA") permits users
              who are California residents to request to exercise certain
              rights. If you are a California resident, the CCPA grants you the
              right to request certain information about our practices with
              respect to your Personal Information. In particular, you can
              request to receive information on the following:
            </p>
            <ul>
              <li>
                The categories and specific pieces of your Personal Information
                that we have collected.
              </li>
              <li>
                The categories of sources from which we collected your Personal
                Information.
              </li>
              <li>
                The business or commercial purposes for which we collected your
                Personal Information.
              </li>
              <li>
                The categories of third parties with which we shared your
                Personal Information.
              </li>
            </ul>
            <p className="para">
              You can rest assure that we do not sell your Personal Information.
              If you choose to exercise your rights, we will not charge you
              different prices or provide different quality of our Services,
              unless those differences are related to your provision of your
              Personal Information.
            </p>
            <p className="para">
              Please note that you must verify your identity and request before
              further action is taken. As a part of this process, government
              identification may be required. Moreover, you may designate an
              authorized agent to make a request on your behalf. 
            </p>
            <p className="para">
              We endeavor to respond to a verifiable consumer request within 45
              days of its receipt. If we require more time (up to 90 days), we
              will inform you of the reason and extension period in writing. Any
              disclosures we provide, will only cover the 12 month period
              preceding your verifiable request's receipt. If, for some reason,
              we cannot reply within such time frame, our response will include
              an explanation for our inability to comply. If you wish to
              exercise your CCPA rights, please contact us at:
              privacy@Mezada.net.
            </p>
            <p className="para">
              We will not discriminate against you for exercising any of your
              CCPA rights. Unless permitted by the CCPA, we will not:
            </p>
            <ul>
              <li>Deny you goods or services.</li>
              <li>
                Charge you different prices or rates for goods or services,
                including through granting discounts or other benefits, or
                imposing penalties.
              </li>
              <li>
                Provide you with a different level or quality of goods or
                services.
              </li>
              <li>
                Suggest that you may receive a different price or rate for goods
                or services or a different level or quality of goods or
                services.
              </li>
            </ul>
            <h2 className="headsinner formargin">CAN SPAM Act</h2>
            <p className="para">
              The CAN-SPAM Act is a Federal US law that sets the rules for
              commercial email, establishes requirements for commercial
              messages, gives recipients the right to have emails stopped from
              being sent to them, and spells out tough penalties for violations.
            </p>
            <p className="para">
              To be in accordance with CANSPAM, we agree to the following:
            </p>
            <ul>
              <li>Not use false or misleading subjects or email addresses.</li>
              <li>
                Identify the commercial message sent to you as an advertisement
                when required. 
              </li>
              <li>
                Include the physical address of our business or site
                headquarters.
              </li>
              <li>
                Monitor third-party email marketing services for compliance, if
                one is used.
              </li>
              <li>Honor opt-out/unsubscribe requests quickly.</li>
              <li>
                Allow users to unsubscribe by using the link at the bottom of
                each email.
              </li>
            </ul>
            <p className="para">
              If at any time you would like to unsubscribe from receiving future
              emails, you can email us at{" "}
              <a href="mailto: privacy@Mezada.net">privacy@Mezada.net</a> and we
              will promptly remove you from ALL correspondence.
            </p>
            <h2 className="headsinner">Children's Privacy</h2>
            <p className="para">
              The Website is not intended for children under the age of 18. We
              do not, knowingly or intentionally, collect information about
              children who are under 18 years of age.
            </p>
            <p className="para parabold">
              IF YOU ARE UNDER THE AGE OF 18 YOU MAY NOT USE THE WEBSITE OR
              CONSUME SERVICES
            </p>
            <h2 className="headsinner">
              Questions Regarding Our Privacy Policy
            </h2>
            <p className="para">
              If you have any questions regarding this Privacy Policy or the
              practices described above, you are always welcome to contact us at{" "}
              <a href="mailto: privacy@Mezada.net">privacy@Mezada.net</a>.
            </p>
            <h2 className="headsinner">
              Revisions and Modifications to our Privacy Policy
            </h2>
            <p className="para">
              We reserve the right to revise, amend, or modify this Privacy
              Policy at any time. When changing the policy, we will update this
              posting accordingly. Please review this Privacy Policy often so
              that you will remain updated regarding our current policies.
            </p>
            <h2 className="headsinner">Governing Law and Jurisdiction</h2>
            <p className="para">
              This Privacy Policy will be governed and interpreted pursuant to
              the laws of the State of Israel without giving effect to its
              choice of law rules. You expressly agree that the exclusive
              jurisdiction for any claim or action arising out of or relating to
              this Privacy Policy shall be to the competent courts in Tel Aviv,
              Israel to the exclusion of any other jurisdiction.
            </p>
            <p className="para parabold">This page was updated on June 3, 2024.</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Privacypolicy;
