import React, { useEffect } from "react";
import "./terms.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <>
      <Header />
      <section className="terms">
        <div className="custom-container">
          <div className="maincontent">
            <h2 className="heads">Terms of Use</h2>
            <p className="para">
              Welcome to Mezada Development and Software Ltd{" "}
              <span> (“Mezada”)</span>!
            </p>
            <p className="para">
              These terms of use govern your use of our website. By accessing or
              using our website, you agree to be bound by these terms. If you
              disagree with any part of these terms, please do not use our
              website.
            </p>
            <h2 className="headsinner">1. Use of Website</h2>
            <p className="para" style={{margin: "10px 0"}}>
              1.1. You must be at least 18 years old to use our website.
            </p>
            <p className="para" style={{margin: "10px 0"}}>
              1.2. You agree to use our website only for lawful purposes and in
              a manner consistent with all applicable laws and regulations.
            </p>
            <p className="para" style={{margin: "10px 0"}} >
              1.3. You agree not to use our website in any way that could
              damage, disable, overburden, or impair our website or interfere
              with any other party's use and enjoyment of our website.
            </p>
            <h2 className="headsinner">2. Intellectual Property</h2>
            <p className="para" style={{margin: "10px 0"}}>
              2.1. The content on our website, including text, graphics, logos,
              images, and software, is the property of Mezada and is protected
              by copyright laws.
            </p>
            <p className="para" style={{margin: "10px 0"}}>
              2.2. You may not modify, reproduce, distribute, display, or
              transmit any portion of our website without the prior written
              consent of Mezada.
            </p>
            <h2 className="headsinner">3. Disclaimer</h2>
            <p className="para">
              The information provided on our website is for general
              informational purposes only. We make no representations or
              warranties of any kind, express or implied, about the accuracy,
              reliability, or suitability of the information contained on the
              website.
            </p>
            <p className="para" style={{margin: "10px 0"}}>
              3.2. We do not guarantee the availability, accuracy, or
              completeness of any information provided on our website. Your use
              of any information on our website is at your own risk.
            </p>
            <h2 className="headsinner">4. Limitation of Liability</h2>
            <p className="para">
              In no event shall Mezada be liable for any direct, indirect,
              incidental, special, or consequential damages arising out of or in
              any way connected with your use of our website.
            </p>
            <h2 className="headsinner">5. Links to Third-Party Websites</h2>
            <p className="para">
              Our website may contain links to third-party websites. These links
              are provided for your convenience only. We have no control over
              the content of these websites and do not endorse or make any
              representations about them. Your use of third-party websites is at
              your own risk.
            </p>
            <h2 className="headsinner">6. Changes to Terms</h2>
            <p className="para">
              We reserve the right to update or modify these terms of use at any
              time without prior notice. Your continued use of our website after
              any such changes constitutes your acceptance of the new terms.
            </p>
            <h2 className="headsinner">7. Governing Law</h2>
            <p className="para">
              These terms of use shall be governed by and construed in
              accordance with the laws of the State of Israel, without regard to
              its conflict of law provisions.
            </p>
            <h2 className="headsinner">8. Contact Us</h2>
            <p className="para">
              If you have any questions or concerns about these terms of use,
              please contact us at{" "}
              <a href="mailto: service@mezada.net">service@mezada.net</a>.
            </p>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Terms;
