import { useState } from "react";
import { HashLink } from 'react-router-hash-link';
import { Link, useHistory, useLocation } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas';
import './header.scss'
const Header = () => {
  const [showsidebar, setShowSidebar] = useState(false);
  const { hash } = useLocation();
  const isActive = (iHash) => hash === iHash;

  const router = useLocation();

  const handleShowSidebar = () => setShowSidebar(true);
  const handleClose = () => setShowSidebar(false);


  const history = useHistory();

  const handleLinkClick = (iHash) => {
    // Navigate to the homepage and add the hash
    history.push(`/#${iHash}`);
  };


  const handleClick = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#home';
    }, 1000)
  }
  const handleClick1 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#specialties';
    }, 1000)
  }
  const handleClick2 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#choose';
    }, 1000)
  }
  const handleClick3 = (e) => {
    setShowSidebar(false);
    e.preventDefault();
    setTimeout(() => {
      window.location.href = '#contact';
    }, 1000)
  }
  return (
    <>
      <section className="main-navbar">
        <div class="custom-container">
          <nav class="navbar navbar-expand-xl">
            <a class="navbar-brand" href="/">
              <img src="\logo.svg" alt="logoimg" className="logoimg" />
            </a>
            <button class="navbar-toggler" onClick={handleShowSidebar}>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12" viewBox="0 0 20 12" fill="none">
                  <path d="M1 11H19M1 6H19M1 1H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </span>
            </button>
            <div class="collapse navbar-collapse mobile-none" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <HashLink to="/#home" class="nav-link" style={
                    isActive("#home")
                      ? {
                        color: "#4DD388",
                      }
                      : {}
                  }> <a>Home</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="/#specialties" class="nav-link" style={
                    isActive("#specialties")
                      ? {
                        color: "#4DD388"
                      }
                      : {}
                  }><a >Our Specialties</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink to="/#choose" class="nav-link" style={
                    isActive("#choose")
                      ? {
                        color: "#4DD388"
                      }
                      : {}
                  }> <a>Why Choose Us</a></HashLink>
                </li>
                <li class="nav-item">
                  <HashLink class="nav-link" to="/#contact" style={
                    isActive("#contact")
                      ? {
                        color: "#4DD388"
                      }
                      : {}
                  }><a>Contact Us</a></HashLink>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/career" style={router.pathname == "/career" ? { color: "#4DD388" } : {}}><a>Career</a></Link>
                </li>

              </ul>
              <div class="d-flex tusfssfsuxfufsu">
                <div className="icons">
                  <HashLink className="btn-common" to="/#contact">contact us</HashLink>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>
      <div className="mobile-menu">
        <Offcanvas placement="top" show={showsidebar} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <img src="\logo.svg" alt="logoimg" className="logoimg" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <HashLink onClick={() => {
                  handleLinkClick('home')
                  // handleClick();
                }} to="#home" class="nav-link" style={
                  isActive("#home")
                    ? {
                      color: "#31F2B3",
                    }
                    : {}
                }> <a>Home</a></HashLink>
              </li>

              <li class="nav-item">
                <HashLink onClick={() => {
                  handleLinkClick('specialties')
                  // handleClick1();
                }} to="#specialties" class="nav-link" style={
                  isActive("#specialties")
                    ? {
                      color: "#31F2B3"
                    }
                    : {}
                }> <a>Our Specialties</a></HashLink>
              </li>
              <li class="nav-item">
                <HashLink onClick={() => {
                  handleLinkClick('choose')
                  // handleClick2();
                }} class="nav-link" to="#choose" style={
                  isActive("#choose")
                    ? {
                      color: "#31F2B3"
                    }
                    : {}
                }><a>Why Choose Us</a></HashLink>
              </li>
              <li class="nav-item">
                <HashLink onClick={() => {
                  handleLinkClick('contact')
                  // handleClick3();
                }} class="nav-link" to="#contact" style={
                  isActive("#contact")
                    ? {
                      color: "#31F2B3"
                    }
                    : {}
                }> <a >Contact Us</a></HashLink>
              </li>
              <li class="nav-item">
                <Link class="nav-link" to="/career" style={router.pathname == "/career" ? { color: "#4DD388" } : {}}><a>Career</a></Link>
              </li>
            </ul>
            <div className="offlast">
              <div className="socials">
                <a href="" target="_blank" className="scoialpara">
                  <img src="\assets\twitter.svg" alt="socialimg" className="socialimg" />
                  Twitter
                </a>
                <a href="" target="_blank" className="scoialpara">
                  <img src="\assets\Telegram.svg" alt="socialimg" className="socialimg" />
                  Telegram
                </a>
                <a href="" target="_blank" className="scoialpara">
                  <img src="\assets\LinkedIn.svg" alt="socialimg" className="socialimg" />
                  LinkedIn
                </a>
              </div>
              <p className="offlastpara">
                Copyright © 2024 <span className="white">Mezada.</span> All Rights Reserved.
              </p>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  )
}

export default Header