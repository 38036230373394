import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Header from '../landing/Header/Header';
import Footer from '../landing/Footer/Footer';
import Apply from '../Form/Apply';
import Loader from '../../utils/Loader';

const CareerDetail2 = () => {

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            {loader && <Loader />}

            <Header />
            <section className="career-detail">
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="back-link">
                                <Link to="/career">Back to All Jobs</Link>
                            </div>
                            <div className="upper-parent">
                                <h4>Head of PR and Communications position at Mezada Technology</h4>
                                <p className='para'>We’re looking for Head of PR and Communications to join our team</p>
                                <div className="bottom-twice">
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>100% Remote</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Full time</p>
                                </div>
                            </div>
                            <div className="bottom-detail">
                                <div className="main-content">
                                    <h6>About us:</h6>
                                    <p>
                                        At Mezada Technology, we firmly believe that the best way to predict the future is to invent it.
                                        We excel in providing cutting-edge tech solutions that meet customer needs and anticipate and adapt
                                        to evolving market trends. With a focus on innovation and customer satisfaction, our team delivers
                                        user-friendly platforms with customizable compensation structures. Whether it's UI/UX design, web
                                        development, mobile development, or blockchain solutions, we offer expertise, personalized solutions,
                                        and a commitment to quality assurance.
                                    </p>

                                    <h6>Job Description:</h6>
                                    <p>
                                        We're hiring a highly experienced <span>Head of Public Relations and Communications</span> with a proven track record
                                        of conducting PR work for crypto or blockchain projects and companies, with strong connections to relevant
                                        media organizations, journalists and KOL’s. The role’s responsibilities will include creating various PR
                                        strategies for the companies’ projects, managing constant interactions with the media, and collaborating
                                        regularly with the marketing department to increase brand awareness and ensure positive community sentiment
                                        alongside crisis identification and crisis management.
                                    </p>

                                    <h6>Job requirements:</h6>
                                    <ul>
                                        <li>Broad experience working with, or in crypto, blockchain, and web3 companies or projects.</li>
                                        <li>+3 years of experience in PR work with financial institutions.</li>
                                        <li>Experience in writing or editing news and promotional material is preferred. A portfolio showcasing their work is a must.</li>
                                        <li>Strong writing and verbal communication skills in English at a native level.</li>
                                        <li>Experience writing, editing, and procuring paid content, including promoted platforms.</li>
                                        <li>Ability to work around the clock and be rapidly responsive to real-time situations.</li>
                                        <li>Ability to initiate and actualize interactions and collaborations with high-profile personas (from within or outside the organization) to create and echo project-related PR, interviews, and supporting content.</li>
                                    </ul>

                                    <h6>Advantages:</h6>
                                    <ul>
                                        <li>Connections with POCs from major media sites like Cointelegraph, Coingecko, CoinDesk, Forbes, Bloomberg Businessweek, TechCrunch, The Wall Street Journal, etc.</li>
                                        <li>Experience with social media and discourse monitoring tools and analysis of audience interaction with PR and marketing content to identify opportunities and potential crises.</li>
                                        <li>Experience with being photographed and interviewed and speaking in front of an audience.</li>
                                        <li>Ability to prepare and tutor company personnel for being photographed, interviewed, and speaking in front of an audience.</li>
                                        <li>Experience in handling sensitive situations and briefing other colleagues on how to do so.</li>
                                    </ul>
                                    <p>Please send your CV to: <a href="mailto: marketing@mezada.net">marketing@mezada.net</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <Apply setLoader={setLoader} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CareerDetail2
