import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Landing from "./components/landing/Landing";
import Terms from "./components/landing/Term/Terms";
import Cookies from "./components/landing/Cookies/Cookies";
import Privacypolicy from "./components/landing/Privacypolicy/Privacypolicy";
import Career from "./components/Career/Career";
import CareerDetail from "./components/Career/CareerDetail";
import CareerDetail1 from "./components/Career/CareerDetail1";
import CareerDetail2 from "./components/Career/CareerDetail2";
import CareerDetail3 from "./components/Career/CareerDetail3";
import CareerDetail4 from "./components/Career/CareerDetail4";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <>
      <ToastContainer style={{ fontSize: 20, width: '400px' }} />

      <Router>
        <Route exact path="/" render={() => <Landing />} />
        <Route exact path="/terms" render={() => <Terms />} />
        <Route exact path="/privacy" render={() => <Privacypolicy />} />
        <Route exact path="/cookies" render={() => <Cookies />} />
        <Route exact path="/career" render={() => <Career />} />
        <Route exact path="/headofmarketing" render={() => <CareerDetail />} />
        <Route exact path="/marketingoperationsmanager" render={() => <CareerDetail1 />} />
        <Route exact path="/headofprandcommunications" render={() => <CareerDetail2 />} />
        <Route exact path="/headofcommunity" render={() => <CareerDetail3 />} />
        <Route exact path="/supportspecialist" render={() => <CareerDetail4 />} />
      </Router>
    </>
  );
}

export default App;
