import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../landing/Header/Header'
import Footer from '../landing/Footer/Footer'
import Modal from 'react-bootstrap/Modal';
import Loader from '../../utils/Loader';
import Apply from '../Form/Apply';

const CareerDetail4 = () => {

    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {loader && <Loader />}

            <Header />
            <section className="career-detail">
                <div className="custom-container">
                    <div className="parent">
                        <div className="left-side">
                            <div className="back-link">
                                <Link to="/career">Back to All Jobs</Link>
                            </div>
                            <div className="upper-parent">
                                <h4>Support Specialist position at Mezada Technology</h4>
                                <p className='para'>We’re looking for Support Specialist to join our team</p>
                                <div className="bottom-twice">
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M3.75 7.44214C3.75 11.081 6.93336 14.0902 8.34239 15.2439C8.54405 15.409 8.64608 15.4926 8.79653 15.5349C8.91368 15.5679 9.0861 15.5679 9.20325 15.5349C9.35398 15.4925 9.4553 15.4098 9.65771 15.244C11.0668 14.0903 14.2499 11.0813 14.2499 7.44247C14.2499 6.06539 13.6968 4.74454 12.7123 3.77079C11.7277 2.79705 10.3924 2.25 9.00006 2.25C7.60767 2.25 6.27226 2.79713 5.28769 3.77087C4.30312 4.74462 3.75 6.06505 3.75 7.44214Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.5 6.75C7.5 7.57843 8.17157 8.25 9 8.25C9.82843 8.25 10.5 7.57843 10.5 6.75C10.5 5.92157 9.82843 5.25 9 5.25C8.17157 5.25 7.5 5.92157 7.5 6.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>On-Site</p>
                                    <p><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                        <path d="M9 5.25V9H12.75M9 15.75C5.27208 15.75 2.25 12.7279 2.25 9C2.25 5.27208 5.27208 2.25 9 2.25C12.7279 2.25 15.75 5.27208 15.75 9C15.75 12.7279 12.7279 15.75 9 15.75Z" stroke="#A3A3A3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>Full time</p>
                                </div>
                            </div>
                            <div className="bottom-detail">
                                <div className="main-content">
                                    <h6>About Us:</h6>
                                    <p>
                                        At Mezada Technology, we firmly believe that the best way to predict the future is to invent it.
                                        We excel in providing cutting-edge tech solutions that meet customer needs and adapt to evolving
                                        market trends. Our team is dedicated to delivering user-friendly platforms with customizable compensation
                                        structures, whether it’s UI/UX design, web development, mobile development, or blockchain solutions.
                                        We offer expertise, personalized solutions, and a commitment to quality assurance.
                                    </p>

                                    <h6>Job Description:</h6>
                                    <p>
                                        We are looking for a highly skilled and motivated Customer Support Specialist to join our dynamic team in Tel Aviv.
                                        The ideal candidate will have a robust background in managing and executing support systems on a global scale,
                                        a strong understanding of cryptography, web3, and blockchain technologies, and a passion for delivering
                                        exceptional customer service.
                                    </p>

                                    <h6>Job requirements:</h6>
                                    <ul>
                                        <li>Extensive experience in managing and executing support systems on a global scale.</li>
                                        <li>Strong understanding of cryptography, web3, and blockchain technologies (mandatory).</li>
                                        <li>Ability to clearly explain technical issues to non-technical users.</li>
                                        <li>Proficiency in Customer Relationship Management (CRM) tools to track and manage customer interactions.</li>
                                        <li>Exceptional written, spoken, and frontal communication skills.</li>
                                        <li>Resourcefulness and the ability to collect & manage inquiries from multiple channels.</li>
                                        <li>Native-level proficiency in English (mandatory), advantage for bonus languages.</li>
                                        <li>Strong organizational skills.</li>
                                    </ul>

                                    <h6>Responsibilities:</h6>
                                    <ul>
                                        <li>Navigate and manage support tickets from various social channels.</li>
                                        <li>Provide prompt and accurate technical support to community members and users.</li>
                                        <li>Work closely with the product department to address user support tickets.</li>
                                        <li>Notify developers of necessary repairs and bugs highlighted through support inquiries.</li>
                                        <li>Recruit and assemble a support representative team as the need arises.</li>
                                        <li>Oversee the training and development of the support team to ensure high service standards.</li>
                                    </ul>

                                    <h6>Advantages:</h6>
                                    <ul>
                                        <li>Knowledge in creating chatbots and automatic support funnels.</li>
                                    </ul>
                                    <p>Please send your CV to: <a href="mailto: marketing@mezada.net">marketing@mezada.net</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="right-side">
                            <Apply setLoader={setLoader} />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CareerDetail4
